import { isProductionSandboxEnvironment } from '@/utils/environment';
import { AppConfig } from './types';

export const LSBX: AppConfig['sponsorBank'] = {
  bank_id: isProductionSandboxEnvironment()
    ? '4abb5f19-1635-48ae-b867-8f56937a537b'
    : '11520f50-d736-4422-9fa6-76fbfd10f963',
  name: 'LSBX',
  questionnaire: {
    questions: [
      {
        options: [
          '$0 - $1,000',
          '$1,001 - $3,000',
          '$3,001 - $5,000',
          '$5,001 - $10,000',
          'Greater than $10,001',
        ],

        placeholder: 'Select a range...',
        questionType: 'multipleChoice',
        text: 'What are your expected ACH monthly SEND volumes?',
      },
      {
        options: [
          '$0 - $1,000',
          '$1,001 - $3,000',
          '$3,001 - $5,000',
          '$5,001 - $10,000',
          'Greater than $10,001',
        ],
        placeholder: 'Select a range...',
        questionType: 'multipleChoice',
        text: 'What are your expected ACH monthly RECEIVE volumes?',
      },
    ],
    title: 'Send/Receive volumes',
  },
  terms: [
    {
      document: 'sda_and_creditcard_agreement.pdf',
      label: 'Deposit account agreement and credit card agreement',
      version: '1.0',
    },
    {
      document: 'bank_privacy_policy.pdf',
      label: 'Lincoln Savings Bank’s privacy policy',
      version: '1.0',
    },
    {
      document: 'bank_electronic_communication_consent.pdf',
      label: 'Lincoln Savings Bank’s electronic communication consent',
      version: '1.0',
    },
  ],
};
